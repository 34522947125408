*{
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans', sans-serif;
  font-display: swap;
}
:root{
  --header-height: 65px;
  --padding-l-r: 7vw;
  font-size: 16px;
}
::selection{
  background-color: blue;
  color: white;
}
html{
  font-size: 16px;
  position: relative;
}
body {
  overflow-x: hidden;
  font-size: 16px;
  background-color: black;
  /* position: relative; */
}
h1,h2,h3,h4,h5,h6,a,button{
  color: white;
  letter-spacing: -0.02em;
  font-weight: 500;
  word-spacing: -0.066em;
}
h1,h2,h3,h4,h5,h6{
  line-height: 1em;
}
p{
  line-height: 1.33em;
  white-space: pre-wrap;
}
h1{
  font-size: 2.66em;
  letter-spacing: -0.077em;
  line-height: 1.2em;
}
h2{
  font-size: 2.33em;
  letter-spacing: -0.066em;
}
h3{
  font-size: 2em;
}
h4{
  font-size: 1.66em;
}
h5{
  font-size: 1.33em;
}
h6{
  font-size: 1.1em;
}
@media screen and (min-width: 768px){
  p{
    line-height: 1.66em;
  }
  h1{
    font-size: 4.66em;
    letter-spacing: -0.066em;
  }
  h2{
    font-size: 3em;
    letter-spacing: -0.044em;
  }
  h3{
    font-size: 2.33em;
    letter-spacing: -0.044em;
  }
  h4{
    font-size: 2em;
  }
  h5{
    font-size: 1.66em;
  }
  h6{
    font-size: 1.33em;
  }
}

p, li{
  color: white;
  letter-spacing: -0.02em;
}
a{
  text-decoration: none;
}
a, button, input[type=checkbox]{
  cursor: pointer;
}
a, button, input, label, p{
  font-size: 1em;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}